import Vue from 'vue'
import Router from 'vue-router'
import Home from '../pages/Home.vue'
import bannerHome from '../pages/bannerHome.vue'
import Login from '../pages/Login.vue'
import Notice from '../pages/Notice.vue'
import NotFound from '../pages/404.vue'
import NoPermission from '../pages/401.vue'
import Account from '../pages/usermanagement/account.vue'
import Binding from '../pages/usermanagement/binding.vue'

import rtlist from '../pages/detectionenvironment/rtlist.vue'

import histable from '../pages/datacenter/histable.vue'
import hisgraph from '../pages/datacenter/hisgraph.vue'
import alarmtable from '../pages/datacenter/alarmtable.vue'
import exceptionhandle from '../pages/datacenter/exceptionhandle.vue'
import hispath from '../pages/datacenter/hispath.vue'
import smstable from '../pages/datacenter/smstable.vue'
import hisdoor from '../pages/datacenter/hisdoor.vue'
import cardhistory from '../pages/datacenter/cardhistory.vue'
import Syslog from '../pages/datacenter/syslog.vue'

import localdevice from '../pages/localwarehouse/localdevice.vue'
import localsensors from '../pages/localwarehouse/localsensors.vue'
import localalarmdevice from '../pages/localwarehouse/localalarmdevice.vue'

import clouddevice from '../pages/cloudwarehouse/clouddevice.vue'
import cloudsensors from '../pages/cloudwarehouse/cloudsensors.vue'
import cloudalarmtable from '../pages/cloudwarehouse/alarmtable.vue'
import cloudalarmdevice from '../pages/cloudwarehouse/alarmdevice.vue'
import content from '../pages/content.vue'

import AlarmPhone from '../pages/alarmphone.vue'

import editdata from '../pages/data/edit.vue'
import sensorExtend from '../pages/data/sensorExtend.vue'
import overdueDevice from '../pages/data/overdue.vue'

Vue.use(Router)

/**
 * icon : the icon show in the sidebar
 * hidden : if `hidden:true` will not show in the sidebar
 * redirect : if `redirect:noredirect` will no redirct in the levelbar
 * noDropdown : if `noDropdown:true` will has no submenu
 * meta : { role: ['admin'] }  will control the page role
 * ['superadmin','superuser','agentadmin','agentuser','admin','user']
 **/

export const constantRouterMap = [
    {
        path: '/login',
        component: Login,
        hidden: true,
    },
    {
        path: '/404',
        component: NotFound,
        hidden: true,
    },
    {
        path: '/401',
        component: NoPermission,
        hidden: true,
    },
    {
        path: '/',
        component: bannerHome,
        redirect: '/notice',
        name: '首页',
        hidden: true,
        children: [
            {
                path: '/notice',
                component: Notice,
                name: '消息中心',
                hidden: true,
            },
        ],
    },
    {
        path: '/',
        component: Home,
        hidden: true,
        children: [
            {
                path: '/mycustomer',
                component: () => import(/* webpackChunkName: "mycustomer" */ '../pages/mycustomer.vue'),
                name: '我的客户',
                hidden: true,
                meta: {
                    role: ['superadmin', 'agentadmin'],
                },
            },
            {
                path: '/mydevice',
                component: () => import(/* webpackChunkName: "mydevice" */ '../pages/mydevice.vue'),
                name: '我的设备',
                hidden: true,
            },
            {
                path: '/customerinfo',
                component: () => import(/* webpackChunkName: "customerinfo" */ '../pages/customerinfo.vue'),
                name: '基本信息',
                hidden: true,
            },
            {
                path: '/content/:id(\\d+)',
                component: content,
                name: '文章内容',
                hidden: true,
            },
            {
                path: '/alarmphone',
                component: AlarmPhone,
                name: '报警电话',
                hidden: true,
            },
        ],
    },
    {
        path: '/',
        component: Home,
        name: '检测环境',
        iconCls: 'fa fa-bank',
        noDropdown: false,
        children: [
            {
                path: '/rtlist',
                component: rtlist,
                except: [76, 289, 381],
                name: '实时数据',
            },
            {
                path: '/distribution/:deviceid',
                component: () => import(/* webpackChunkName: "distribution" */ '../pages/detectionenvironment/distribution.vue'),
                hidden: true,
                name: '测点分布',
            },
            {
                path: '/rtpath/:devicecode/:devicetype',
                component: () => import(/* webpackChunkName: "rtpath" */ '../pages/detectionenvironment/rtpath.vue'),
                hidden: true,
                name: '实时轨迹',
            },
            {
                path: '/warehouse',
                component: () => import(/* webpackChunkName: "warehouse" */ '../pages/detectionenvironment/warehouse.vue'),
                except: [76, 289, 381],
                name: '库房',
            },
            {
                path: '/cloudwarehouse',
                component: () => import(/* webpackChunkName: "cloudwarehouse" */ '../pages/detectionenvironment/cloudwarehouse.vue'),
                name: '云库房',
            },
            {
                path: '/incubator',
                component: () => import(/* webpackChunkName: "incubator" */ '../pages/detectionenvironment/incubator.vue'),
                name: '保温箱',
            },
            {
                path: '/refrigeratedcar',
                component: () => import(/* webpackChunkName: "refrigeratedcar" */ '../pages/detectionenvironment/refrigeratedcar.vue'),
                name: '冷藏车',
            },
            {
                path: '/stlbox',
                component: () => import(/* webpackChunkName: "stlbox" */ '../pages/detectionenvironment/stlbox.vue'),
                name: 'STL冷链箱',
            },
        ],
    },
    {
        path: '/dataCenter',
        component: Home,
        name: '数据中心',
        iconCls: 'fa fa-area-chart',
        noDropdown: false,
        children: [
            {
                path: '/dataCenter/histable',
                component: histable,
                name: '历史数据',
            },
            {
                path: '/dataCenter/cardhistory',
                component: cardhistory,
                name: 'Card历史数据',
                hidden: true,
            },
            {
                path: '/dataCenter/hisdoor',
                component: hisdoor,
                name: '开关门数据',
            },
            {
                path: '/dataCenter/hisgraph',
                component: hisgraph,
                name: '历史图表',
            },
            {
                path: '/dataCenter/alarmtable',
                component: alarmtable,
                name: '报警列表',
            },
            {
                path: '/dataCenter/exceptionhandle',
                component: exceptionhandle,
                name: '异常处理',
            },
            {
                path: '/dataCenter/smstable',
                component: smstable,
                name: '短信记录',
            },
            {
                path: '/dataCenter/hispath',
                component: hispath,
                name: '历史轨迹',
            },
            {
                path: '/dataCenter/syslog',
                component: Syslog,
                name: '操作日志',
            },
        ],
    }
]

export default new Router({
    mode: 'hash',
    routes: constantRouterMap
})
export const asyncRouterMap = [
    {
        path: '/local',
        component: Home,
        name: '本地设备配置',
        iconCls: 'fa fa-cog',
        except: [76, 289, 381],
        children: [
            {
                path: '/local/device',
                component: localdevice,
                name: '设备管理',
            },
            {
                path: '/local/sensors',
                component: localsensors,
                name: '传感器管理',
            },
            {
                path: '/local/compalarmes',
                component: localalarmdevice,
                name: '报警管理',
            },
        ],
    },
    {
        path: '/cloud',
        component: Home,
        name: '云端设备配置',
        iconCls: 'fa fa-cloud',
        children: [
            {
                path: '/cloud/device',
                component: clouddevice,
                name: '设备管理 ',
            },
            {
                path: '/cloud/sensors',
                component: cloudsensors,
                name: '传感器管理 ',
            },
            {
                path: '/cloud/alarmable',
                component: cloudalarmtable,
                name: '云端报警管理',
            },
            {
                path: '/cloud/alarmdevice',
                component: cloudalarmdevice,
                name: '报警器管理',
            },
        ],
    },
    {
        path: '/',
        component: Home,
        name: '用户管理',
        meta: {
            role: ['superadmin', 'agentadmin', 'admin']
        },
        noDropdown: false,
        iconCls: 'fa fa-id-card-o',
        children: [{
            path: '/account',
            component: Account,
            meta: {
                role: ['superadmin', 'agentadmin', 'admin']
            },
            name: '帐户管理'
        }, {
            path: '/binding',
            component: Binding,
            name: '设备绑定'
        }]
    },
    {
        path: "*",
        component: NotFound,
        hidden: true
    }]

export const agentLevelRouterMap =
{
    path: '/data',
    component: Home,
    name: '数据服务',
    redirect: '/edit',
    iconCls: 'el-icon-s-data',
    children: [
        {
            path: '/data/overdue',
            component: overdueDevice,
            name: '到期设备',
        },
        {
            path: '/data/edit',
            component: editdata,
            name: '更改数据',
            meta: {
                editControl: true
            },
        },
        {
            path: '/data/formula',
            component: sensorExtend,
            name: '偏差调整',
            meta: {
                editControl: true
            },
        },
    ],
}
