import { asyncRouterMap, constantRouterMap, agentLevelRouterMap } from '@/router'

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.role) {
    return roles.some(role => route.meta.role.indexOf(role) >= 0)
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(asyncRouterMap, roles) {
  const accessedRouters = asyncRouterMap.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, { routers, customerid }) => {
      state.addRouters = routers
      const allRouters = constantRouterMap.concat(routers)
      state.routers = allRouters.filter(route => {
        if (route.except && route.except.indexOf(customerid) > -1) {
          return false;
        } else {
          if (route.children && route.children.length) {
            route.children = route.children.filter(x => {
              if (x.except && x.except.indexOf(customerid) > -1) {
                return false;
              } else {
                return true
              }
            })
          }
          return true
        }
      })
    }
  },
  actions: {
    GenerateRoutes({ commit }, data) {
      return new Promise(resolve => {
        const { roles, customerid, editable } = data
        let accessedRouters
        if (roles.indexOf('admin') >= 0) {
          accessedRouters = asyncRouterMap
        } else {
          accessedRouters = filterAsyncRouter(asyncRouterMap, roles)
        }
        if (editable !== 1) {
          agentLevelRouterMap.children = agentLevelRouterMap.children.filter(childRouter => {
            return childRouter.meta?.editControl !== true
          })
        }
        accessedRouters.push(agentLevelRouterMap)
        commit('SET_ROUTERS', { routers: accessedRouters, customerid })
        resolve()
      })
    }
  }
}

export default permission
