<template>
    <section>
        <!--工具条-->
        <el-col class="toolbar" :span="24" style="padding-bottom: 0px;">
            <el-form :inline="true" :model="search" :rules="searchRules">
                <el-form-item>
                    <template>
                        <span>到期时间：</span>
                        <el-select v-model="search.overdate" placeholder="请选择条件">
                            <el-option v-for="item in overdueOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="search.includeoverdue">包含已过期的设备</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button v-on:click="resetPageAndSearch">查询</el-button>
                </el-form-item>

            </el-form>
        </el-col>

        <!--列表-->
        <el-table :data="resultList" v-loading="listLoading" stripe highlight-current-row>
            <el-table-column type="index" width="65">
            </el-table-column>
            <el-table-column prop="ExpiredAt" :formatter="formatTime" label="到期时间" width="180" sortable>
            </el-table-column>
            <el-table-column prop="DeviceName" label="设备名称" width="120" sortable show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="DeviceCode" label="设备号" min-width="150" sortable show-overflow-tooltip>
            </el-table-column>
            <el-table-column prop="Customer.CustomerName" label="客户名称" min-width="250" sortable show-overflow-tooltip>
            </el-table-column>
        </el-table>

        <!--工具条-->
        <el-col :span="24">
            <el-pagination layout="prev, pager, next, jumper" @current-change="handleCurrentChange" :page-size="20"
                :total="total" :current-page="page" style="float:right;">
            </el-pagination>
        </el-col>
    </section>
</template>

<script>
import NProgress from "nprogress";
import moment from 'moment'
export default {
    data() {
        return {
            search: {
                overdate: moment().format('YYYY-MM-DD'),
                includeoverdue: false
            },
            overdueOptions: [
                { value: moment().format('YYYY-MM-DD'), label: '迄今' },
                { value: moment().add(1, 'week').format('YYYY-MM-DD'), label: '一周内' },
                { value: moment().add(1, 'month').format('YYYY-MM-DD'), label: '一个月内' },
                { value: moment().add(3, 'month').format('YYYY-MM-DD'), label: '三个月内' },
                { value: moment().add(6, 'month').format('YYYY-MM-DD'), label: '半年内' },
            ],
            total: 0,
            page: 1,
            listLoading: false,
            searchRules: {},
            resultList: []
        };
    },
    computed: {
        searchUseable: function () {
            if (this.search.daterange == "" || this.search.daterange == null)
                return true;
            else return false;
        }
    },
    methods: {
        formatTime: (row, column) => {
            return new Date(row.ExpiredAt).format("yyyy年mm月dd日");
        },
        resetPageAndSearch() {
            this.page = 1
            this.searchOverdueList()
        },
        handleCurrentChange(val) {
            this.page = val;
            this.searchOverdueList();
        },
        searchOverdueList() {
            let page = this.page;
            let params = {
                ...this.search,
                page,
            };
            this.listLoading = true;

            NProgress.start();
            this.axios.post("/admin/device/overdue", params).then(res => {
                this.listLoading = false;
                NProgress.done();
                if (res.data.code === 0) {
                    this.resultList = res.data.values.list;
                    this.total = res.data.values.count;
                } else {
                    this.$message({
                        message: `查询出错，错误码${res.data.code},请联系管理员`,
                        type: "warning"
                    });
                }
            });
        }
    },
    mounted() { }
};
</script>
<style></style>