<template>
  <el-row>
    <el-col :span="24">
      <el-input placeholder="设备号/名称" v-model="filterName" clearable></el-input>
    </el-col>
    <el-col :span="24" v-for="s in filteredlocaldeviceslist" :key="s.deviceid">
      <h2>
        {{ s.devicename }}({{ s.devicecode }})
        <router-link :to="'/distribution/' + s.deviceid">
          <span>
            <el-button type="primary" size="small">测点分布</el-button>
          </span>
        </router-link>
      </h2>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="o in s.sensors" :key="o.sensorid">
        <el-card
          :class="(new Date().getTime() / 1000) - o.keepalive < 60 * o.recordinterval ? ' time-online' : ' time-offline'">
          <div class="cardleft">
            <div class="label">{{ o.sensorcode }}#</div>
            <div>{{ o.warehousename }}</div>
          </div>
          <div class="cardright">
            <div>
              <span v-if="Boolean(o.flag & 0b0011)" style="color:red;font-weight: bold;">温度：{{ o.temperature }}℃</span>
              <span v-else>温度：{{ o.temperature }}℃</span>
            </div>
            <div>
              <span v-if="Boolean(o.flag & 0b1100)" style="color:red;font-weight: bold;">湿度：{{ o.humidity }}%</span>
              <span v-else>湿度：{{ o.humidity }}%</span>
            </div>
            <div class="time">
              {{ (new Date().getTime() / 1000) - o.keepalive < 60 * o.recordinterval ? "更新时间：" + o.updatetime : "离线中"
                }}</div>
            </div>
        </el-card>
      </el-col>
    </el-col>
    <el-col :span="24" v-for="s in filteredclouddeviceslist" :key="s.deviceid">
      <h2>
        {{ s.devicename }}({{ s.devicecode }})
        <router-link v-if="s.devicetype == 2" :to="'/distribution/' + s.deviceid">
          <span>
            <el-button type="primary" size="small">测点分布</el-button>
          </span>
        </router-link>
        <router-link v-else :to="'/rtpath/' + s.devicecode + '/' + s.devicetype">
          <span>
            <el-button type="primary" size="small">实时轨迹</el-button>
          </span>
        </router-link>
      </h2>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" v-for="o in s.sensors" :key="o.sensorid">
        <el-card
          :class="(new Date().getTime() / 1000) - o.keepalive < 60 * o.recordinterval ? ' time-online' : ' time-offline'">
          <el-tooltip effect="light" placement="right-start">
            <div slot="content" class="cardright">
              <span v-if="s.devicetype != 6">电压：{{ o.vcc || "- -" }} V</span>
              <span v-else>电量：{{ o.vcc * 100 || "- -" }} %</span>
              <br>
              <div>信号：{{ o.dbm || "- -" }} dBm</div>
            </div>
            <div class="cardleft">
              <div class="label">{{ o.sensorcode }}#</div>
              <div>{{ o.warehousename }}</div>
            </div>
          </el-tooltip>
          <div class="cardright">
            <div>
              <span v-if="Boolean(o.flag & 0b0011)" style="color:red;font-weight: bold;">温度：{{ o.temperature }}℃</span>
              <span v-else>温度：{{ o.temperature }}℃</span>
            </div>
            <div v-if="s.devicetype != 6 && o.sensorkind === 0">
              <span v-if="Boolean(o.flag & 0b1100)" style="color:red;font-weight: bold;">湿度：{{ o.humidity }}%</span>
              <span v-else>湿度：{{ o.humidity }}%</span>
            </div>
            <div class="time">
              {{ (new Date().getTime() / 1000) - o.keepalive < 60 * o.recordinterval ? "更新时间：" + o.updatetime : "离线中"
                }}</div>
            </div>
        </el-card>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      warehousetype: 0,
      localdeviceslist: [],
      clouddeviceslist: [],
      filteredlocaldeviceslist: [],
      filteredclouddeviceslist: [],
      socket: null,
      interval: null,
      filterName: ''
    };
  },
  watch: {
    filterName: function (val) {
      if (val.trim() === '') {
        this.filteredclouddeviceslist = this.clouddeviceslist;
        this.filteredlocaldeviceslist = this.localdeviceslist;
      } else {
        this.filteredclouddeviceslist = this.clouddeviceslist.filter(device => device.devicename.includes(val) || device.devicecode.includes(val))
        this.filteredlocaldeviceslist = this.localdeviceslist.filter(device => device.devicename.includes(val) || device.devicecode.includes(val))
      }
    }
  },
  computed: {
    ...mapGetters(["customerid"])
  },
  methods: {
    destroySocket: function () {
      this.socket.close();
    }
  },
  beforeDestroy() {
    let that = this;
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    setTimeout(function () {
      that.destroySocket();
    }, 5000);
    // 五秒后断开websocket
  },
  mounted() {
    let that = this;
    that.interval = setInterval(() => { this.$forceUpdate() }, 60000)
    that.axios
      .post("/detection/getlocalwarehouses", {
        customerid: that.customerid
      })
      .then(res => {
        that.localdeviceslist = res.data.values.deviceslist;
        that.filteredlocaldeviceslist = that.localdeviceslist;
      });
    that.axios
      .post("/detection/getcloudwarehouses", {
        customerid: that.customerid,
        warehousetype: this.warehousetype
      })
      .then(res => {
        that.clouddeviceslist = res.data.values.deviceslist.sort(function (a, b) {
          return a.devicename > b.devicename ? 1 : -1;
        });
        that.filteredclouddeviceslist = that.clouddeviceslist;
      });
    that.socket = new WebSocket(`wss://${window.location.hostname == 'localhost' ? 'www.coldcloud.com' : window.location.hostname.replace(".cn", "")}:30000`);
    that.socket.onopen = event => {
      // 通知wsServer当前浏览器的客户ID
      that.socket.send(that.customerid);

      that.socket.onmessage = msg => {
        // 只处理实时数据，过滤实时GPS数据
        if (msg.data.indexOf(",") < 0) {
          return;
        }
        console.log(msg.data);
        let {
          0: customerid,
          1: devicecode,
          2: sensorcode,
          3: temperature,
          4: humidity,
          5: flag,
          6: vcc,
          7: dbm
        } = msg.data.split(",");
        that.localdeviceslist.map(deviceInfo => {
          if (deviceInfo.devicecode == devicecode) {
            deviceInfo.sensors.map(sensorInfo => {
              if (sensorInfo.sensorcode == sensorcode) {
                sensorInfo.temperature = temperature;
                sensorInfo.humidity = humidity;
                sensorInfo.flag = flag;
                sensorInfo.updatetime = new Date().format("mm-dd HH:MM");
                sensorInfo.keepalive = Number.parseInt(new Date().getTime() / 1000)
              }
            });
          }
        });
        that.clouddeviceslist.map(deviceInfo => {
          if (deviceInfo.devicecode == devicecode) {
            deviceInfo.sensors.map(sensorInfo => {
              if (sensorInfo.sensorcode == sensorcode) {
                sensorInfo.temperature = temperature;
                if (sensorInfo.sensorkind == 0) {
                  sensorInfo.humidity = humidity;
                }
                sensorInfo.flag = flag;
                sensorInfo.updatetime = new Date().format("mm-dd HH:MM");
                sensorInfo.vcc = vcc;
                sensorInfo.dbm = dbm;
                sensorInfo.keepalive = Number.parseInt(new Date().getTime() / 1000)
              }
            });
          }
        });
      };
      that.socket.onclose = closeEvent => {
        // that.$message({
        // 	message: 'websocket断开',
        // 	type: 'warning'
        // });
      };
      that.socket.onerror = errorEvent => {
        // that.$message({
        // 	message: 'websocket出错',
        // 	type: 'warning'
        // });
      };
    };
  }
};
</script>

<style scoped>
.time {
  font-size: 13px;
  color: #999;
  overflow: hidden;
  line-height: 1.5em;
  min-height: 3em;
  max-height: 3em;
}

.time-online {
  margin: 0.5rem 1rem;
  border-radius: 6px;
  position: relative;
  z-index: 0;
}

.time-online:before {
  content: '';
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  border-radius: 4px;
  background: linear-gradient(45deg, red, orange, yellow, green, cyan, blue, purple, red);
  filter: blur(3px);
  background-size: 1000%;
  z-index: -2;
  animation: animate 50s linear infinite;
}

.time-online::after {
  content: '';
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background-color: rgb(250, 250, 250);
  border-radius: 6px;
  z-index: -1;
  opacity: 1;
}

.time-offline {
  margin: 0.5rem 1rem;
  border-radius: 6px;
  position: relative;
  z-index: 0;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 1000% 0;
  }
}

.spanright {
  float: right;
}

.cardleft {
  width: 80px;
  float: left;
  min-height: 86px;

  .label {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #10c469;
    float: left;
    margin: 5px 20px;
  }

  div:nth-child(2) {
    width: 80px;
    height: auto;
    overflow: hidden;
    position: relative;
    line-height: 1.5em;
    max-height: 3em;
    transition: max-height 0.3s ease-in-out;
  }

  div:nth-child(2):hover {
    max-height: none;
    transition: max-height 0.3s ease-in-out;
  }
}

.cardright {
  line-height: 22px;
  min-height: 86px;
}

h2 {
  line-height: 45px;
  font-size: 18px;
  padding-left: 15px;
  width: 97%;
  margin: 12px auto;
  background: #f1f2f7;
  /* font-weight: normal; */
}

h2 span {
  float: right;
  padding-right: 15px;
}
</style>